import Grid from "@mui/material/Grid";
import ScoreRadar from "./score-radar";
import ScoreBreakdown from "./score-breakdown";

import {ScoreData as ScoreDataType} from "./types";

export interface ScoreDataParams {
  overallScore: number;
  chart: { series: { data: number[]; name: string }[]; categories: (string | string[])[] };
  scoreData: ScoreDataType;
}

export function ScoreData({overallScore, chart, scoreData}: ScoreDataParams) {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} lg={4} xl={4}>
        <ScoreBreakdown title="Score" subheader="Scores are from 1 to 5" overallScore={overallScore}
                        scoreData={scoreData}/>
      </Grid>
      <Grid item xs={12} lg={8} xl={8}>
        <ScoreRadar
          score={overallScore}
          chart={chart}
        />
      </Grid>
    </Grid>);
}
