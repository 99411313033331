import React, {useEffect, useState} from "react";
import {Box, Card, Collapse, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios, {endpoints} from "../../utils/axios";
import AiProgressbar from "../utility_components/ai-progressbar";
import Iconify from "../iconify";
import {NewsAnalysisType} from "./news_news";
import NewsContainer from "./news_container";
import Label from "../label";
import {customScrollbarStyles} from "../../theme/css";
import GeneratedOn from "../utility_components/generated-on";
import {useCredits} from "../../providers/credits-provider";


interface CompanyDocumentsProps {
  ticker: string | undefined;
  setAiAnalysesReady?: (key: string) => void | undefined;
  renderCompact?: boolean;
}


export default function NewsAnalysis({
                                       ticker,
                                       setAiAnalysesReady,
                                       renderCompact = false
                                     }: CompanyDocumentsProps) {
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [newsAnalysis, setNewsAnalysis] = useState<NewsAnalysisType>({
    themes: [],
    news: [],
  });
  const [analysisDatetimeGenerated, setAnalysisDatetimeGenerated] = useState<string>('');
  const [themeIdToShowNews, setThemeIdToShowNews] = useState<boolean[]>([]);
  const {refreshCredits} = useCredits();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const hoverStyles = {
    transition: 'transform 0.3s ease-in-out',
    "&:hover": {
      cursor: "pointer",
      transform: 'scale(1.01)' // Slightly enlarge on hover
    },
    '&:hover .theme-title': {
      color: 'primary.main'
    }
  }


  const getLabelElement = (sentiment: string) => {
    switch (sentiment) {
      case "good":
        return <Label color="success"> Good </Label>
      case "bad":
        return <Label color="error"> Bad </ Label>
      case "neutral":
        return <Label color="info"> Neutral </ Label>
      default:
        return <Label color="info"> Neutral </ Label>
    }
  }

  useEffect(() => {
    const getNewsAnalysis = async () => {
      try {
        setIsLoadingAnalysis(true);
        const analysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=news`);
        const parsedAnalysis = JSON.parse(analysisResponse.data.answer);

        // sort themes by number of news
        parsedAnalysis.themes = parsedAnalysis.themes.sort((a: any, b: any) => b.news_ids.length - a.news_ids.length);

        setNewsAnalysis(parsedAnalysis);
        setAnalysisDatetimeGenerated(analysisResponse.data.datetime_generated);
        setThemeIdToShowNews(new Array(parsedAnalysis.themes.length).fill(false));
      } catch (error) {
        console.error("Error fetching company news AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('news');
        }
        refreshCredits();
      }

      setIsLoadingAnalysis(false);
    }
    getNewsAnalysis();

  }, [ticker, refreshCredits, setAiAnalysesReady]);

  if (isLoadingAnalysis) {
    return <AiProgressbar/>
  }

  const renderThemesMobile = () => (
    <>
      {newsAnalysis.themes.map((t, index) => (
        <Box key={t.theme_title} sx={{mt: 2, mx: 2}}>
          <Stack direction="row" spacing={1} onClick={() => {
            setThemeIdToShowNews(prevState => {
              const newState = [...prevState];
              newState[index] = !newState[index];
              return newState;
            });
          }}
                 sx={{mt: 1, ...hoverStyles}}
          >
            {getLabelElement(t.label)}
            <Typography className="theme-title" variant="body2"
                        fontWeight="bold">{t.theme_title}</Typography>
            <Box>
              {themeIdToShowNews[index] ? <Iconify icon="eva:chevron-up-fill" width={24}/> :
                <Iconify icon="eva:chevron-down-fill" width={24}/>}
            </Box>
          </Stack>
          <Typography variant="body2" sx={{my: 2, mb: 1}}>{t.theme_analysis}</Typography>
          <Button variant="text" color="primary"
                  onClick={() => {
                    setThemeIdToShowNews(prevState => {
                      const newState = [...prevState];
                      newState[index] = !newState[index];
                      return newState;
                    });
                  }}
                  sx={{fontSize: 11, ml: 1}}
          >
            {themeIdToShowNews[index] ? "Hide News" : "Show News"}
          </Button>
          <Collapse in={themeIdToShowNews[index]}>
            <NewsContainer
              news={newsAnalysis.news.filter((n) => newsAnalysis.themes[index].news_ids.includes(n.id))}/>
          </Collapse>
        </Box>
      ))}
    </>
  )

  const renderThemesDesktop = () => (
    <>
      {newsAnalysis.themes.map((t, index) => (
        <Card key={t.theme_title} sx={{my: 2, mx: 1}}>
          <Grid container spacing={1} sx={{width: '100%', p: 3}}>
            <Grid item xs={4}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                  Recurring Theme
                </Typography>
                <Iconify icon="mdi:lightbulb-on-outline" width={20}/>
              </Stack>

              <Stack direction="row" spacing={1} sx={{mt: 1, ...hoverStyles}}>
                {getLabelElement(t.label)}
                <Typography className="theme-title" variant="body2"
                            fontWeight="bold">{t.theme_title}</Typography>
              </Stack>
              <Typography variant="body2" sx={{my: 2, mb: 1}}>{t.theme_analysis}</Typography>
            </Grid>
            <Grid item xs={8}>
                            <Stack direction="row" spacing={1}>
                <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                  News
                </Typography>
                <Iconify icon="material-symbols:news-outline" width={20}/>
              </Stack>
              <Box sx={{
                maxHeight: '300px', // Adjust this value to fit exactly 5 lines of text
                overflowY: 'auto', // Enable vertical scrolling
                width: "100%",
                ...customScrollbarStyles(theme)
              }}>
                <NewsContainer
                  news={newsAnalysis.news.filter((n) => newsAnalysis.themes[index].news_ids.includes(n.id))}/>

              </Box>
            </Grid>
          </Grid>

        </Card>
      ))}
    </>
  )

  return (
    <Box
      sx={{
        maxHeight: '600px', // Adjust this value to fit exactly 5 lines of text
        overflowY: 'auto', // Enable vertical scrolling
        mb: 1,
        ...customScrollbarStyles(theme)
      }}
    >
      <GeneratedOn dateToDisplay={analysisDatetimeGenerated} textVariant="caption" textPrefix="Last updated" timeAgo/>

      {isMobile || renderCompact ? renderThemesMobile() : renderThemesDesktop()}
    </Box>
  )
}
