import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend, Line, LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import React from "react";

export const renderLineChart = (chart: any) => {
  if (!chart) {
    return null;
  }

  const data = chart.chart_data.labels.map((label: string, index: number) => ({
    label,
    value: chart.chart_data.values[index],
  }));

  // Example data
  // {label: '2021', value: 117.93}
  // {label: '2022', value: 116.61}
  // {label: '2023', value: 134.9}

  return (
    <Box>
      <Typography variant="h6" textAlign="center">{chart.chart_title}</Typography>
      <Typography variant="body2" textAlign="center">(in {chart.chart_data_scale})</Typography>
      <Box sx={{m: 5}}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis
              dataKey="label"
              interval={0}
              tickFormatter={(label) => label.length > 20 ? `${label.substring(0, 18)}...` : label}
              padding={{ left: 20, right: 20 }}
            />
            <YAxis/>
            <Tooltip/>
            <Line type="linear" dataKey="value" stroke="#8884d8"/>
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export const renderBarChart = (chart: any) => {
  if (!chart) {
    return null;
  }

  const data = chart.chart_data.labels.map((label: string, index: number) => ({
    label,
    value: chart.chart_data.values[index],
  }));

  return (
    <Box>
      <Typography variant="h6" textAlign="center">{chart.chart_title}</Typography>
      <Typography variant="body2" textAlign="center">(in {chart.chart_data_scale})</Typography>
      <Box sx={{m: 5}}>
        <ResponsiveContainer width="100%" height={350}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis
              dataKey="label"
              interval={0}
              tickFormatter={(label) => label.length > 20 ? `${label.substring(0, 18)}...` : label}
            />
            <YAxis/>
            <Tooltip/>
            <Bar dataKey="value" fill="#8884d8"/>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

// Example colors for pie slices
const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#a4de6c", "#d0ed57", "#8dd1e1"];

export const renderPieChart = (chart: any) => {
  if (!chart) {
    return null;
  }

  const data = chart.chart_data.labels.map((label: string, index: number) => ({
    name: label,
    value: chart.chart_data.values[index],
  }));

  return (
    <Box>
      <Typography variant="h6" textAlign="center">{chart.chart_title}</Typography>
      <Typography variant="body2" textAlign="center">(in {chart.chart_data_scale})</Typography>
      <Box sx={{m: 5}}>
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={120}
              fill="#8884d8"
              label
            >
              {data.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
              ))}
            </Pie>
            <Tooltip/>
            <Legend/>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
