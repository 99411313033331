import {useTheme} from "@mui/material/styles";
import Iconify from "../iconify";

export default function CustomCheckbox({checked}: { checked: boolean }) {
  const theme = useTheme();

  if (checked) {
    return (
      <Iconify icon="fluent:checkbox-checked-24-filled" width={42} sx={{ color: theme.palette.primary.main }} />
    );
  }
  return (
    <Iconify icon="fluent:checkbox-unchecked-24-filled" width={42} />
  );
}
