import Box from "@mui/material/Box";
import React, {useState} from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Iconify from "../iconify";


export default function HelpIcon({text}: { text: string }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event: any, popContent: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popContent);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };
  const hoverStyles = {
    '&:hover': {
      cursor: 'help',
    },
  };
  return (
    <>
      <Box component="span"
           onMouseEnter={(e) => handlePopoverOpen(e, text)}
           onMouseLeave={handlePopoverClose}
           sx={{...hoverStyles}}

      >
        <Iconify icon="material-symbols:help" width={25}/>
      </Box>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
        sx={{pointerEvents: 'none'}}
        slotProps={{
          paper: {style: {width: 250}},
        }}
      >
        <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
          {popoverContent}
        </Typography>
      </Popover>
    </>
  );
}
