import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {Checkbox, Button} from "@mui/material";
import {ContextDocument} from "./types";
import EarningsDialog from "../../earnings_call_analysis/earnings_dialog";
import CustomCheckbox from "../custom_checkbox";
import GeneratedOn from "../../utility_components/generated-on";
import Iconify from "../../iconify";

export default function ContextDocumentEarningsTranscript({document, title, isSelected, onSelected}: {
  document: ContextDocument,
  title: string,
  isSelected: boolean,
  onSelected: (id: string) => void,
}) {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="row" spacing={2}
               onClick={() => onSelected(document.id)}
               sx={{
                 cursor: 'pointer',
                 '&:hover': {
                   backgroundColor: theme.palette.action.hover,
                 },
               }}
        >
          <CustomCheckbox checked={isSelected}/>
          <Stack direction="column" spacing={1}>
            <Typography>{title}</Typography>
            <GeneratedOn dateToDisplay={document.documentDate} textVariant="caption" textPrefix="Published on"
                         sx={{mt: 0}}/>
          </Stack>
        </Stack>
        <Button variant="outlined" onClick={() => setDialogOpen(true)}
                startIcon={<Iconify icon="tabler:external-link" width={24}/>}
        >
          View Document
        </Button>
      </Stack>

      {document.annotatedContent && document.annotatedContent.length > 0 && (
        <EarningsDialog
          dialogOpen={dialogOpen}
          setDialogOpen={(open: boolean) => setDialogOpen(open)}
          // @ts-ignore
          paragraphs={document.annotatedContent || []}
          searchString=""
          date={document.documentDate}
          quarter={document.quarter}
        />
      )}


    </>
  )
}
