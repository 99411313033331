import Label from "../../label";

export default function ContextDocumentLabel({type}: { type: string }) {
  switch (type) {
    case "10-K":
      return <Label color="default" sx={{cursor: "pointer", width: '100%'}}> 10-K </Label>;
    case "10-Q":
      return <Label color="primary" sx={{cursor: "pointer", width: '100%'}}> 10-Q </Label>;
    case "PRESS-RELEASE":
      return <Label color="secondary" sx={{cursor: "pointer", width: '100%'}}> Press Release </Label>;
    case "EARNINGS-CALL":
      return <Label color="info" sx={{cursor: "pointer", width: '100%'}}> Earnings Call </Label>;
    default:
      return <Label color="success" sx={{cursor: "pointer", width: '100%'}}> Press Release </Label>;
  }
};
