import React, {useState} from 'react';
import {Box, Button, CircularProgress, Stack, TextField, Typography,} from '@mui/material';

import {useTheme} from "@mui/material/styles";

import axios, {endpoints} from '../../utils/axios';
import Iconify from '../iconify';


export default function TemplateQuestion({
                                           question,
                                           templateId,
                                           setTemplates,
                                           handleDeleteQuestion,
                                         }: {
  question: any,
  templateId: string,
  setTemplates: any,
  handleDeleteQuestion: (templateId: string, questionId: string) => void
}) {
  const theme = useTheme();
  const [newQuestion, setNewQuestion] = useState(question.question || ''); // Initialize with existing question text
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    // Implement save logic if required (e.g., API call to save the updated question)
    if (!newQuestion.trim()) {
      alert('Question cannot be empty');
      return;
    }
    setIsSaving(true);

    try {
      const resp = await axios.put<any>(`${endpoints.template_questions}/${question.id}`, {
        template_id: templateId,
        text: newQuestion,
      });
      setTemplates(resp.data)
    } catch (error) {
      console.error('Error updating question:', error);
    } finally {
      setIsSaving(false);
    }

    setIsEditing(false);
  };

  const handleCancelClick = () => {
    // Reset the input field to the original question and stop editing
    setNewQuestion(question.question);
    setIsEditing(false);
  };

  return (
    <Box>
      <Stack
        key={question.id}
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {isEditing ? (
          <TextField
            fullWidth
            multiline
            rows={5}
            value={newQuestion}
            variant="outlined"
            size="small"
            onChange={(e) => setNewQuestion(e.target.value)}
          />
        ) : (
          <Typography variant="body2"
                      onClick={() => setIsEditing(true)}
                      sx={{
                        cursor: 'pointer',
                        "&:hover": {
                          backgroundColor: theme.palette.action.hover,
                        }
                      }}
          >{question.question}</Typography>
        )}
        <Stack direction="row" spacing={1}>
          {isEditing ? (
            <>

              <Button onClick={handleCancelClick}
                      disabled={isSaving}>
                Cancel
              </Button>
              <Button onClick={handleSaveClick}
                      disabled={isSaving}
                      startIcon={isSaving ? <CircularProgress size={16}/> : null}>
                Save
              </Button>

            </>
          ) : (
            <>
              <Button onClick={handleEditClick}>
                <Iconify icon="mi:edit" width={24}/>
              </Button>
              <Button
                disabled={isSaving}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteQuestion(templateId, question.id);
                }}
              >
                <Iconify icon="mi:delete" width={24}/>
              </Button>
            </>
          )}

        </Stack>
      </Stack>
    </Box>
  );
}
