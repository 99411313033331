import React, {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {CircularProgress, Typography} from "@mui/material";
import Templates from "./templates/templates";
import axios, {endpoints} from "../../utils/axios";
import ContextDocuments from "./context_documents/context_documents";
import CustomAnalysisList from "./custom_analysis_list";
import {AnalysisTemplate, ContextDocument} from "./context_documents/types";
import CustomSearchbar from "../../layouts/_common/custom-searchbar/custom-searchbar";
import {paths} from "../../routes/paths";
import {useBoolean} from "../../hooks/use-boolean";
import Iconify from "../iconify";

export default function CustomAnalysis() {
  const {ticker} = useParams<{ ticker?: string }>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const [templates, setTemplates] = useState<AnalysisTemplate[]>([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [answerObjects, setAnswerObjects] = useState<any[]>([]);
  const [analyses, setAnalyses] = useState<any[]>([]);
  const [contextDocumentsAvailable, setContextDocumentsAvailable] = React.useState<ContextDocument[]>([]);
  const [generateAnalysisLoading, setGenerateAnalysisLoading] = useState<boolean>(false);
  const [getDocumentsLoading, setGetDocumentsLoading] = useState<boolean>(false);
  const [getAnalysesLoading, setGetAnalysesLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const [hasAnimated, setHasAnimated] = React.useState(true);

  const getAnalyses = useCallback(async () => {
    if (!ticker) {
      return;
    }
    try {
      setGetAnalysesLoading(true);
      const endpoint = `/api/custom-analysis/${ticker}`;
      const response = await axios.get(endpoint);
      console.log("Analyses response:", response.data);
      setAnalyses(response.data);
    } catch (error) {
      console.error("Error generating analysis:", error);
    } finally {
      setGetAnalysesLoading(false);
    }
  }, [ticker]);

  // get analyses for the ticker once initially
  useEffect(() => {
    getAnalyses();
  }, [getAnalyses]);

  useEffect(() => {
    const fetchContextDocuments = async () => {
      if (ticker) {
        // call /custom-analysis/documents/{ticker} and set the response to contextDocumentsAvailable
        console.log("Fetching context documents for ticker:", ticker);
        setGetDocumentsLoading(true);
        try {
          const endpoint = `/api/custom-analysis/documents/${ticker}`;
          const response = await axios.get(endpoint);
          console.log("Context documents response:", response.data);
          setContextDocumentsAvailable(response.data);
        } catch (error) {
          console.error("Error fetching context documents:", error);
        } finally {
          setGetDocumentsLoading(false);
        }
      }
    }

    fetchContextDocuments();
  }, [ticker]);

  useEffect(() => {
    const getAnalysisTemplates = async () => {
      try {
        // setLoading(true);
        const result = await axios.get<any>(`${endpoints.analysis_template}`, {
          params: {report_type: 'EARNINGS-CALL'},
        });
        console.log('Fetched templates:', result.data);
        setTemplates(result.data);
      } catch (error) {
        console.error('Error fetching analysis templates:', error);
      } finally {
        // setLoading(false);
      }
    };

    getAnalysisTemplates();
  }, []);

  const navigate = useNavigate();
  const handleCompanyAddClick = async (tick: string, onDialogClose: () => void) => {
    // setTicker(tick);
    navigate(`${paths.main.custom_analysis.root}/${tick}`);
  }

  const renderSearchButton = (search: ReturnType<typeof useBoolean>) => (
    <Button
      color="inherit"
      variant="outlined"
      startIcon={<Iconify icon="eva:search-outline" width={24}/>}
      onClick={() => search.onTrue()}
      sx={{borderColor: 'text.primary'}}
    >
      Select Company
    </Button>
  );

  const generateAnalysis = async () => {
    if (selectedTemplateId && selectedDocumentIds.length > 0) {
      try {
        setGenerateAnalysisLoading(true);
        const endpoint = `/api/custom-analysis/${ticker}`;
        const response = await axios.post(endpoint, {
          templateId: selectedTemplateId,
          documentIds: selectedDocumentIds
        });
        setAnswerObjects(response.data);
        setTimeout(() => {
          setHasAnimated(false);
        }, 2000);
      } catch (error) {
        console.error("Error generating analysis:", error);
      } finally {
        setGenerateAnalysisLoading(false);
        getAnalyses();
      }
    }
  }

  const renderSearch = () => (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
      <Box id="search-button" sx={{mt: 1, position: "relative"}}>
        <CustomSearchbar
          renderSearchButton={renderSearchButton}
          handleCompanyClickCustom={handleCompanyAddClick}
          country="us"
          tickersToHide={[]}/>
      </Box>
    </Stack>
  )

  if (!ticker) {
    return (
      <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" sx={{mt: 5}}>
        <Typography variant="h4">Select a company</Typography>
        <Typography variant="body2">Please select a company to analyze</Typography>
        {renderSearch()}
      </Stack>
    );
  }

  return (
    <Stack className="custom-analysis" direction="column" sx={{my: 3, width: "100%"}} justifyContent="center"
           alignItems="center">
      <Stack direction="column" spacing={5} sx={{width: "100%", maxWidth: "1000px"}} alignItems="flex-start">
        {renderSearch()}

        <Stack direction="column" spacing={2} sx={{width: "100%"}}>
          <Typography variant="h6">Your Analyses</Typography>
          <CustomAnalysisList ticker={ticker} analyses={analyses} documentsAvailable={contextDocumentsAvailable}
                              templatesAvailable={templates}
                              loading={getAnalysesLoading} setSearchString={setSearchString}
                              hasAnimated={hasAnimated} setHasAnimated={setHasAnimated}
          />
        </Stack>

        <Stack direction="column" spacing={2} sx={{width: "100%"}}>
          <Typography variant="h6">1. Select a template</Typography>
          <Templates selectedTemplateId={selectedTemplateId} setSelectedTemplateId={setSelectedTemplateId}
                     templates={templates}
                     setTemplates={setTemplates}
          />
        </Stack>

        <Stack direction="column" spacing={2} sx={{width: "100%"}}>
          <Typography variant="h6">2. Select context documents</Typography>
          <ContextDocuments ticker={ticker} selectedDocuments={selectedDocumentIds}
                            setSelectedDocuments={setSelectedDocumentIds} searchString={searchString}
                            contextDocumentsAvailable={contextDocumentsAvailable} isLoading={getDocumentsLoading}
          />
        </Stack>


        <Stack direction="row" justifyContent="flex-end" sx={{width: '100%'}}>
          {generateAnalysisLoading ? (
            <Button variant="contained"
                    size="large"
                    onClick={generateAnalysis}
                    disabled
                    startIcon={<CircularProgress size={24}/>}>
              Generating
            </Button>
          ) : (
            <Button variant="contained"
                    size="large"
                    onClick={generateAnalysis}
                    disabled={!selectedTemplateId || selectedDocumentIds.length === 0 || templates.find(t => t.id === selectedTemplateId)?.questions.length === 0}
                    startIcon={<Iconify icon="mdi:sparkles-outline" width={24}/>}>
              Generate Analysis
            </Button>
          )}
        </Stack>
      </Stack>

    </Stack>
  )
}
