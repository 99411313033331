import {currencyToSymbol} from "./constants";

// @ts-ignore
export const getCurrencySymbol = (currency: string) => currencyToSymbol[currency] || currency

export const scrollToElement = (locationHash: { hash: string }, block: string = "center") => {
  const {hash} = locationHash;
  if (hash) {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        // @ts-ignore
        block
      });
    } else {
      console.error('Element not found with id', id);
    }
  }
};

export const getProgressBarColor = (value: number) => {
  // Map the score to a color gradient from red to light green
  const red = Math.max(255 - (value * 2.55), 0); // Closer to 0, more red
  const green = Math.min(value * 2.55, 200); // Closer to 100, lighter green (adjust to your preference)
  // Convert RGB values to a CSS color string
  return `rgb(${red.toFixed(0)}, ${green.toFixed(0)}, 0)`;
};

export enum UserTypes {

  PremiumWithCredits = 'premium_with_credit',
  PremiumNoCredits = 'premium_no_credits',
  FreeWithCredits = 'free_with_credits',
  FreeNoCredits = 'free_no_credits',
  Anonymous = 'anonymous',
}

export const getUserType = (user: any, ticker: string | undefined) => {
  if (!user?.subscription || !user.subscription.is_active) {
    // free user
    if (user?.user_credits.credits <= 0 && (!user?.user_visited_companies.includes(ticker))) {
      return UserTypes.FreeNoCredits;
    }
    return UserTypes.FreeWithCredits;
  }
  if (user?.subscription && user?.subscription.is_active) {
    if (user?.user_credits.credits <= 0 && (!user?.user_visited_companies.includes(ticker))) {
      return UserTypes.PremiumNoCredits;
    }
    return UserTypes.PremiumWithCredits;
  }
  return UserTypes.Anonymous;
};
