import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {CircularProgress} from "@mui/material";
import {ContextDocument} from "./types";
import ContextDocumentEarningsTranscript from "./context_document_earnings_transcript";
import ContextDocumentPressRelease from "./context_document_press_release";

export default function ContextDocuments({
                                           ticker,
                                           contextDocumentsAvailable,
                                           selectedDocuments,
                                           setSelectedDocuments,
                                           isLoading,
                                           searchString
                                         }: {
  ticker: string | undefined,
  contextDocumentsAvailable: ContextDocument[],
  selectedDocuments: string[],
  setSelectedDocuments: (selectedDocuments: (prevState: any) => any) => void,
  isLoading: boolean,
  searchString: string | "",
}) {

  const onCheckboxClick = (id: string) => {
    // if id is in selectedDocuments, remove it, otherwise add it
    setSelectedDocuments((prevState: string[]) => {
      if (prevState.includes(id)) {
        return prevState.filter((docId) => docId !== id);
      }
      return [...prevState, id];
    })
  }

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress/>
      </Stack>
    )
  }

  return (
    <Stack sx={{width: "100%"}} justifyContent="center">
      <Stack direction="column" spacing={2}>
        {contextDocumentsAvailable.map((doc, index) => (
          <Box key={`doc-${index}`}>
            {doc.type === "EARNINGS-CALL" && (
              <ContextDocumentEarningsTranscript document={doc} title="Earnings Call | Transcript" isSelected={selectedDocuments.includes(doc.id)}
                                                 onSelected={onCheckboxClick}/>
            )}
            {doc.type === "PRESS-RELEASE" && (
              <ContextDocumentPressRelease document={doc} title="Press Release | EX-99.1" isSelected={selectedDocuments.includes(doc.id)}
                                           onSelected={onCheckboxClick}/>
            )}
            {doc.type === "10-K" && (
              <ContextDocumentPressRelease document={doc} title="Annual Report | 10-K" isSelected={selectedDocuments.includes(doc.id)}
                                           onSelected={onCheckboxClick}/>
            )}
            {doc.type === "10-Q" && (
              <ContextDocumentPressRelease document={doc} title="Quarterly Report | 10-Q" isSelected={selectedDocuments.includes(doc.id)}
                                           onSelected={onCheckboxClick}/>
            )}

          </Box>
        ))}
      </Stack>
    </Stack>
  )
}
