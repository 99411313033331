import React, {useCallback, useEffect} from "react";
import {
  Box,
  Button,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import {Link} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import {shouldUseLocalLogo} from "../company/constants";
import PriceChartMinimal from "../price-chart/price-chart-minimal";
import PercentageChange from "../price-chart/percentage-change";
import {LoginToAccess} from "../utility_components/login-to-access";
import {BecomePremiumAccess} from "../utility_components/become-premium-access";
import NewsAnalysis from "../company/news_analysis";
import {useBoolean} from "../../hooks/use-boolean";
import Iconify from "../iconify";
import CustomSearchbar from "../../layouts/_common/custom-searchbar/custom-searchbar";
import AiProgressbar from "../utility_components/ai-progressbar";
import {customScrollbarStyles} from "../../theme/css";
import ScoreBreakdownProgressElement from "../company/score-breakdown-progress-element";
import {useAuthContext} from "../../auth/hooks";

const tableFontSize = "0.9rem";

const defaultCompany = {
  symbol: "",
  companyName: "",
  companyOutlook: {
    profile: {
      image: ""
    }
  },
  priceLast3Months: []
}

export default function WatchlistDesktop({
                                           companies,
                                           onAddCompany,
                                           onRemoveCompany,
                                           isLoading = false
                                         }: {
  companies: any[],
  onAddCompany: any,
  onRemoveCompany: any,
  isLoading?: boolean
}) {
  const theme = useTheme();
  const [currentCompany, setCurrentCompany] = React.useState(defaultCompany);
  const {authenticated} = useAuthContext();


  useEffect(() => {
    if (currentCompany.symbol === "" && companies.length > 0) {
      setCurrentCompany(companies[0]);
    }
  }, [companies, currentCompany]);

  const renderTable = () => (
    <Box sx={{
      mt: 1, mb: 1, width: '100%',

      maxHeight: '700px', // Adjust this value to fit exactly 5 lines of text
      overflowY: 'auto', // Enable vertical scrolling
      ...customScrollbarStyles(theme)
    }}>
      <TableContainer component={Paper}>
        <Table
          sx={{
            fontSize: 10, '& .MuiTableCell-root': {fontSize: tableFontSize}
          }}>
          <TableHead>
            <TableRow>
              <TableCell align="center"/>
              <TableCell>Name</TableCell>
              <TableCell align="center">7D</TableCell>
              <TableCell align="center">3M</TableCell>
              <TableCell align="center">Last 3 Months</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Score</TableCell>
              <TableCell align="center"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* @ts-ignore */}
            {companies.length > 0 && companies.map((company, index) => (
              <TableRow
                key={index}
                onClick={() => setCurrentCompany(company)}
                sx={{
                  '&:last-child td, &:last-child th': {border: 0},
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: theme.palette.action.hover,
                  },
                  backgroundColor: currentCompany.symbol === company.symbol ? theme.palette.action.hover : 'inherit',
                  textDecoration: 'none',
                  color: 'inherit'
                }}
              >
                <TableCell align="center">
                  <Box component="img"
                       src={shouldUseLocalLogo.has(company.symbol) ? `../../assets/images/company_logos/${company.symbol}.png` : company.companyOutlook.profile.image}
                       sx={{maxWidth: 40, maxHeight: 40}}/>
                </TableCell>
                <TableCell>
                  <Typography sx={{fontSize: tableFontSize}}>{company.companyName}</Typography>
                  <Typography sx={{
                    color: 'text.secondary',
                    fontSize: tableFontSize,
                  }}>{company.symbol}</Typography>
                </TableCell>
                <TableCell align="center" sx={{p: 0, height: 115}}>
                  {company.priceLast3Months?.length > 6 && (
                    <PercentageChange leastRecentPrice={company.priceLast3Months[6][1]}
                                      mostRecentPrice={company.priceLast3Months[0][1]}
                                      fontSize={tableFontSize}/>
                  )}
                </TableCell>
                <TableCell align="center" sx={{p: 0, height: 115}}>
                  {company.priceLast3Months?.length > 40 && (
                    <PercentageChange
                      leastRecentPrice={company.priceLast3Months[company.priceLast3Months.length - 1][1]}
                      mostRecentPrice={company.priceLast3Months[0][1]} fontSize={tableFontSize}/>
                  )}
                </TableCell>
                <TableCell align="center" sx={{p: 0, height: 115}}>
                  {company.priceLast3Months?.length > 0 && (
                    <PriceChartMinimal prices={company.priceLast3Months}/>
                  )}
                </TableCell>

                <TableCell align="center">
                  {company.priceLast3Months?.length > 0 && (
                    <Typography
                      sx={{fontSize: tableFontSize}}>${`${company.priceLast3Months[0][1].toFixed(2)}`}</Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={
                      <>
                        <Typography variant="body2"
                                    sx={{fontSize: 12}}>Score: {company.score.toFixed(2)} /
                          5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Valuation
                          Score: {company.score_data.price_data?.score?.toFixed(2)} / 5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Financial Strength
                          Score: {company.score_data.solvency_data?.score?.toFixed(2)} / 5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Efficiency
                          Score: {company.score_data.efficiency_data?.score?.toFixed(2)} /
                          5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Growth
                          Score: {company.score_data.growth_data?.score?.toFixed(2)} / 5</Typography>
                      </>
                    }
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.1}>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement score={company.score_data?.price_data?.score}
                                                       widthParam="8px" heightParam="32px" isVertical
                                                       sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>V</Typography>
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement score={company.score_data?.solvency_data?.score}
                                                       widthParam="8px" heightParam="32px" isVertical
                                                       sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>F</Typography>
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement
                          score={company.score_data?.efficiency_data?.score}
                          widthParam="8px" heightParam="32px" isVertical
                          sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>E</Typography>
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement score={company.score_data?.growth_data?.score}
                                                       widthParam="8px" heightParam="32px" isVertical
                                                       sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>G</Typography>
                      </Stack>

                    </Stack>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="close"
                    onClick={(event) => {
                      event.stopPropagation();
                      setCurrentCompany(defaultCompany);
                      onRemoveCompany(company.symbol);
                    }}
                  >
                    <Iconify icon="eva:trash-outline" width={22}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderNews = () => (
    <>
      <Box className="header" sx={{mt: 2}}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box
              component="img"
              sx={{
                maxHeight: 50,
                maxWidth: 60,
              }}
              alt="Company logo"
              src={shouldUseLocalLogo.has(currentCompany.symbol) ? `../../assets/images/company_logos/${currentCompany.symbol}.png` : currentCompany.companyOutlook.profile.image}
            />
            <Box sx={{ml: "20px"}}>
              <Typography id="company-info" variant="h5" sx={{
                color: 'text.primary',
                width: '250px', // Set a fixed width
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}>
                {currentCompany.companyName}
              </Typography>
              <Typography variant="body1" sx={{color: 'text.secondary'}}>
                ${currentCompany.symbol}
              </Typography>
            </Box>
          </Stack>

          <Box>
            <Button
              color="inherit"
              variant="outlined"
              sx={{borderColor: 'text.primary', minWidth: 155}}
              startIcon={<Iconify icon="eva:arrow-forward-fill" width={24}/>}
              component={Link}
              to={`/companies/${currentCompany.symbol}`}
            >
              View Company
            </Button>
          </Box>

        </Stack>
      </Box>
      <Box className="news-summary" sx={{mb: 3}}>
        <LoginToAccess returnTo={`/companies/${currentCompany.symbol}`}
                       featureDescription="the latest news analysis"
                       imageUrl="/assets/images/blurred/news.png"
        >
          <BecomePremiumAccess ticker={currentCompany.symbol}
                               returnTo={`/companies/${currentCompany.symbol}`}
                               featureDescription="the latest news analysis"
                               imageUrl="/assets/images/blurred/news.png"

          >
            <NewsAnalysis ticker={currentCompany.symbol} renderCompact/>
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>
    </>
  );

  const renderSearchButton = (search: ReturnType<typeof useBoolean>) => (
    <Button
      color="inherit"
      variant="outlined"
      startIcon={<Iconify icon="eva:plus-fill" width={24}/>}
      onClick={() => search.onTrue()}
      sx={{borderColor: 'text.primary'}}
    >
      Add to Watchlist
    </Button>
  );

  const handleCompanyAddClick = async (ticker: string, onDialogClose: () => void) => {
    await onAddCompany(ticker);
    onDialogClose();
  }



  const renderWatchlist = () => (
    <>
      <Box sx={{mt: 1}}>
        <CustomSearchbar renderSearchButton={renderSearchButton}
                         handleCompanyClickCustom={handleCompanyAddClick}
                         tickersToHide={companies.map(c => c.symbol)}/>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={7}>
          {isLoading && (
            <AiProgressbar text="Watchlist loading..."/>
          )}

          {companies.length > 0 && !isLoading && (
            renderTable()
          )}
        </Grid>
        <Grid item xs={5}>
          {isLoading && (
            <AiProgressbar text="News analysis loading..."/>
          )}
          {companies.length > 0 && currentCompany.symbol !== "" && (
            renderNews()
          )}
        </Grid>
      </Grid>
    </>
  )

  if (companies.length <= 0 && !isLoading && authenticated) {
    return (
      <Stack direction="column" alignItems="center">
        <Typography variant="body2">Nothing here!</Typography>
        <Typography variant="body2">Add companies to your watchlist to track their
          performance.</Typography>
        <Box sx={{mt: 2}}>
          <CustomSearchbar renderSearchButton={renderSearchButton}
                           handleCompanyClickCustom={handleCompanyAddClick}
                           tickersToHide={companies.map(c => c.symbol)}/>
        </Box>
      </Stack>
    );
  }


  return (
    <>
      <Typography variant="body2" sx={{color: "text.secondary"}}>Your companies of
        interest with the latest news summarized on the right.</Typography>

      <LoginToAccess returnTo="/dashboard" featureDescription="the watchlist" >
        {renderWatchlist()}
      </LoginToAccess>
    </>
  );
}
