import React, { useState, useEffect, FC } from 'react';

interface TypewriterProps {
  text: string;
  speed?: number;
  onComplete?: () => void;
}

const Typewriter: FC<TypewriterProps> = ({ text, speed = 100, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;
    const length = text.length;
    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + text.charAt(index));
      // eslint-disable-next-line no-plusplus
      index++;
      if (index === length) {
        clearInterval(interval);
        if (onComplete) onComplete();
      }
    }, speed);
    return () => clearInterval(interval);
  }, [text, speed, onComplete]);

  return <span>{displayedText}</span>;
};

export default Typewriter;
