import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {useTheme} from '@mui/material/styles';

import axios, {endpoints} from '../../../utils/axios';
import Iconify from '../../iconify';
import TemplateQuestion from '../template_question';
import {AnalysisTemplate} from '../context_documents/types';
import CustomCheckbox from "../custom_checkbox";

// Define the prop types for the AddEditTemplateDialog component
interface TemplateDialogProps {
  open: boolean;
  handleClose: () => void;
  setTemplates: (templates: AnalysisTemplate[]) => void;
  templateToEdit?: AnalysisTemplate | null; // Add this prop to pass the template being edited
}

// Helper function to structure questions
const structureQuestions = (questions: any): { id: number; question: string }[] => {
  if (Array.isArray(questions)) {
    return questions.map((question: string, idx: number) => ({
      id: idx, // Change to 0-based index
      question,
    }));
  }
  if (typeof questions === 'string') {
    return questions.split('\n').map((question: string, idx: number) => ({
      question: question.trim(),
      id: idx, // Change to 0-based index
    }));
  }
  return [];
};

// Dialog Component that collects Title and Description
function AddEditTemplateDialog({open, handleClose, setTemplates, templateToEdit}: TemplateDialogProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (templateToEdit) {
      setTitle(templateToEdit.title);
      setDescription(templateToEdit.description || '');
    } else {
      setTitle('');
      setDescription('');
    }
  }, [templateToEdit]);

  const onCreateOrUpdate = async () => {
    if (!title.trim()) {
      alert('Title cannot be empty');
      return;
    }

    setIsLoading(true);
    try {
      if (templateToEdit) {
        // Update existing template
        const result = await axios.put<any>(`${endpoints.analysis_template}/${templateToEdit.id}`, {
          title,
          description,
          report_type: 'EARNINGS-CALL',
        });

        // Update templates state with the new list of templates returned from the API
        setTemplates(result.data);
      } else {
        // Create new template
        const result = await axios.post<any>(`${endpoints.analysis_template}`, {
          title,
          description,
          report_type: 'EARNINGS-CALL',
        });

        // Update templates state with the new list of templates returned from the API
        setTemplates(result.data);
      }

      // Close the dialog after saving
      handleClose();
      setTitle('');
      setDescription('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{templateToEdit ? 'Edit Template' : 'New Template'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          multiline
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={onCreateOrUpdate}
            disabled={isLoading || !title.trim()} // Disable if loading or title is empty
            variant="contained"
          >
            {isLoading && <CircularProgress size={24}/>}
            {!isLoading && (templateToEdit ? 'Update' : 'Create')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

// Component for rendering the list of templates
function TemplatesList({
                         templates,
                         loading,
                         setSelectedTemplateId,
                         selectedTemplateId,
                         handleRowClick,
                         expandedRowIndex,
                         onAddTemplate,
                         handleDeleteTemplate,
                         handleDeleteQuestion,
                         setTemplates,
                         handleEditTemplate,
                       }: any) {
  const [newQuestion, setNewQuestion] = useState(''); // State for the new question
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  // Function to handle adding a new question to the template
  const handleAddQuestion = async (templateId: string) => {
    if (!newQuestion.trim()) {
      alert('Question cannot be empty');
      return;
    }

    setIsLoading(true);

    try {
      // Make the POST request to add a new question
      await axios.post<AnalysisTemplate>(`${endpoints.template_questions}`, {
        template_id: templateId,
        text: newQuestion,
        report_type: 'EARNINGS-CALL',
      });

      // Update the template state by adding the new question to the correct template
      setTemplates((prevTemplates: AnalysisTemplate[]) =>
        prevTemplates.map((template) =>
          template.id === templateId
            ? {...template, questions: [...template.questions, newQuestion]}
            : template
        )
      );
      setNewQuestion(''); // Clear the input field after adding the question
    } catch (error) {
      console.error('Error adding question:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="templateTable">
      {loading ? (
        <CircularProgress/>
      ) : (
        <Stack direction="column" spacing={1}>
          {templates.length === 0 && (
            <Typography variant="body1" textAlign="center">
              No templates available. Create a new template to get started.
            </Typography>
          )}
          {templates.map((template: AnalysisTemplate, index: number) => (
            <Box
              key={template.id}
              onClick={() => setSelectedTemplateId(template.id)}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1}>
                  <CustomCheckbox checked={selectedTemplateId === template.id}/>
                  <Stack direction="column">
                    <Typography variant="body1" textAlign="left">
                      {template.title}
                    </Typography>
                    {template.description ? (
                      <Typography variant="caption" textAlign="left">
                        {template.description.length > 80
                          ? `${template.description.substring(0, 77)}...`
                          : template.description}
                      </Typography>
                    ) : (
                      <br/>
                    )}
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Button
                    variant="text"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the row click
                      handleRowClick(index);
                    }}
                  >
                    {expandedRowIndex === index ? <Iconify icon="mdi:chevron-up" width={24}/> :
                      <Iconify icon="mdi:chevron-down" width={24}/>}
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the row click
                      handleEditTemplate(template);
                    }}
                  >
                    <Iconify icon="mdi:pencil" width={24}/>
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the row click
                      handleDeleteTemplate(template.id); // Call the delete function
                    }}
                  >
                    <Iconify icon="mi:delete" width={24}/>
                  </Button>
                </Stack>
              </Stack>
              <Collapse in={expandedRowIndex === index} timeout="auto" unmountOnExit sx={{mt: 1}}>
                <Stack direction="column" spacing={1}>
                  {structureQuestions(template.questions).map((row) => (
                    <TemplateQuestion
                      key={row.id}
                      question={row}
                      templateId={template.id}
                      setTemplates={setTemplates}
                      handleDeleteQuestion={handleDeleteQuestion}
                    />
                  ))}

                  <Stack direction="row" spacing={2} alignItems="center" sx={{mt: 2}}>
                    <TextField
                      label="Add Question"
                      multiline
                      rows={3}
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      fullWidth
                      size="small"
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddQuestion(template.id)}
                      disabled={isLoading || !newQuestion.trim()}
                    >
                      {isLoading ? <CircularProgress size={24}/> : 'Add Question'}
                    </Button>
                  </Stack>
                </Stack>
              </Collapse>
            </Box>
          ))}
          <Stack direction="row" justifyContent="flex-end">
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ooui:template-add-ltr" width={24}/>}
              onClick={onAddTemplate}
              sx={{borderColor: 'text.primary'}}
            >
              New Template
            </Button>
          </Stack>

        </Stack>
      )}
    </Box>
  );
}

// Main Component that contains the button, dialog, and table logic
const Templates = ({
                     selectedTemplateId,
                     setSelectedTemplateId,
                     templates,
                     setTemplates,
                   }: {
  selectedTemplateId: string | null;
  setSelectedTemplateId: (id: string | null) => void;
  templates: AnalysisTemplate[];
  setTemplates: (templates: AnalysisTemplate[]) => void;
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState<AnalysisTemplate | null>(null);
  const [loading, setLoading] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const handleAddTemplate = () => {
    setTemplateToEdit(null);
    setEditDialogOpen(true);
  };

  const handleEditTemplate = (template: AnalysisTemplate) => {
    setTemplateToEdit(template);
    setEditDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setEditDialogOpen(false);
    setTemplateToEdit(null);
  };

  const handleRowClick = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleDeleteTemplate = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        setLoading(true);
        await axios.delete<any>(`${endpoints.analysis_template}`, {
          params: {
            template_id: id,
            report_type: 'EARNINGS-CALL',
          },
        }); // Call the delete endpoint
        console.log('Deleted template:', id);
        // @ts-ignore
        const newTemplates = templates.filter((template) => template.id !== id);
        setTemplates(newTemplates); // Update state to remove deleted template
      } catch (error) {
        console.error('Error deleting template:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteQuestion = async (templateId: string, questionId: number) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      try {
        setLoading(true);

        // Call the delete API with the templateId and question_id
        await axios.delete<any>(`${endpoints.template_questions}`, {
          params: {
            template_id: templateId,
            question_id: questionId, // Use the question's index or unique identifier
            report_type: 'EARNINGS-CALL',
          },
        });

        // Update the state by filtering out the deleted question
        const templatesWithDeletedQuestion = templates.map((template) =>
          template.id === templateId
            ? {
              ...template,
              questions: template.questions.filter((q: string, idx: number) => idx !== questionId), // Remove by index
            }
            : template
        );
        setTemplates(templatesWithDeletedQuestion);
      } catch (error) {
        console.error('Error deleting question:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Stack direction="column" spacing={2} sx={{width: '100%'}}>
      <TemplatesList
        templates={templates}
        loading={loading}
        setSelectedTemplateId={setSelectedTemplateId}
        selectedTemplateId={selectedTemplateId}
        handleRowClick={handleRowClick}
        expandedRowIndex={expandedRowIndex}
        onAddTemplate={handleAddTemplate}
        handleDeleteTemplate={handleDeleteTemplate}
        handleDeleteQuestion={handleDeleteQuestion}
        setTemplates={setTemplates}
        handleEditTemplate={handleEditTemplate}
      />
      <AddEditTemplateDialog
        open={editDialogOpen}
        handleClose={handleCloseDialog}
        setTemplates={setTemplates}
        templateToEdit={templateToEdit}
      />
    </Stack>
  );
};

export default Templates;
