import React, {useEffect} from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {Button, Link as MuiLink} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import {customScrollbarStyles} from "../../../theme/css";
import Iconify from "../../iconify";
import {scrollToElement} from "../../company/utils";

export default function ContextDocumentDialog({content, url, date, dialogOpen, setDialogOpen, scrollToElementId}: {
  content: string | string[],
  url: string,
  date: string,
  dialogOpen: boolean,
  setDialogOpen: (open: boolean) => void,
  scrollToElementId?: string
}) {
  const theme = useTheme();

  useEffect(() => {
    if (dialogOpen && scrollToElementId) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          const substringElement = document.getElementById(scrollToElementId);
          if (substringElement) {
            scrollToElement({hash: `${scrollToElementId}`});
            // make the element with a yellow background
            // style="background-color: yellow; font-weight: bold;"
            substringElement.style.backgroundColor = "yellow";
            substringElement.style.fontWeight = "bold";
          }
        });
      }, 500);
    }
  }, [dialogOpen, scrollToElementId, content]);

  if(!content) {
    return null;
  }

  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}
            fullWidth
            maxWidth="xl"
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="material-symbols-light:call-log-outline"/>
          <Typography variant="h6">Press Release</Typography>
        </Stack>

        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setDialogOpen(false)}
          aria-label="close"
          sx={{position: "absolute", right: 14, top: 8}}
        >
          <CloseIcon/>
        </IconButton>
        <Typography variant="body2" sx={{mt: 1, color: "text.secondary"}}>
          Press release from {date} | <MuiLink
          href={url}
          target="_blank">original
          document <Iconify icon="eva:external-link-fill" width={10}/></MuiLink>
        </Typography>
      </DialogTitle>
      <DialogContent sx={{whiteSpace: "pre-line"}}>
        {
          typeof content === "string" && (
            <Box dangerouslySetInnerHTML={{__html: content}}
                 sx={{
                   m: 5,
                   '.currentSection': {
                     backgroundColor: theme.palette.grey[200],
                     padding: 1,
                     borderRadius: 0.5,
                   },
                   '.currentlySelected': {
                     backgroundColor: theme.palette.info.light,
                     padding: 1,
                     borderRadius: 0.5,
                     my: 1,
                     transition: 'transform 2s ease-in-out',
                     transform: 'scale(1.02)',
                     fontSize: '14px !important',
                   },
                   ...customScrollbarStyles(theme),
                 }}/>
          )
        }
        {
          Array.isArray(content) && (
            content.map((paragraph, index) => (
                <Box sx={{mt: 1}} key={index} id={`vh_element_${index+1}`}>
                  {paragraph.split(/:(.*)/).map((text: any, idx: any) => (
                    <Typography
                      key={idx}
                      variant="body2"
                      fontWeight={idx === 0 ? 700 : 400}
                      dangerouslySetInnerHTML={{
                        __html: text.trim(),
                      }}
                    />
                  ))}
                </Box>
              ))
          )

        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
