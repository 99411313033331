import React, {useEffect, useState} from "react";
import {Grid, LinearProgress} from "@mui/material";

import axios, {endpoints} from "../../utils/axios";
import CompanyGrowthChart, {EstimatedGrowthData} from "./company-growth-chart";
import CompanyGrowthAiAnalysis from "./company-growth-ai-analysis";
import AiProgressbar from "../utility_components/ai-progressbar";

interface CompanyGrowthProps {
  ticker: string | undefined;
  setAiAnalysesReady?: (key: string) => void | undefined;
}


export default function CompanyGrowth({ticker, setAiAnalysesReady}: CompanyGrowthProps) {
  const [growthData, setGrowthData] = useState<EstimatedGrowthData>({
    revenueChange: 0,
    ebitdaChange: 0,
    epsChange: 0,
    estimatedRevenueGrowth: [],
    estimatedEbitdaGrowth: [],
    estimatedEpsGrowth: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [growthAnalysis, setGrowthAnalysis] = useState<any>({});

  useEffect(() => {
    const getCompanyGrowth = async () => {
      setIsLoading(true);
      try {
        const growthResponse = await axios.get<any>(`/api/companies/growth/${ticker}`);
        setGrowthData(growthResponse.data);
      } catch (error) {
        console.error("Error fetching company growth:", error);
      }
      setIsLoading(false);
    }

    const getCompanyGrowthAnalysis = async () => {
      setIsLoadingAnalysis(true);
      try {
        const valuationAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=growth`);
        setGrowthAnalysis(JSON.parse(valuationAnalysisResponse.data.answer));
      } catch (error) {
        console.error("Error fetching company growth AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('growth');
        }
      }
      setIsLoadingAnalysis(false);
    }
    getCompanyGrowth();
    getCompanyGrowthAnalysis();
  }, [ticker, setAiAnalysesReady]);

  if (isLoading) {
    return <LinearProgress/>;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6} sx={{mb: 3}}>
        {isLoadingAnalysis && <AiProgressbar/>}
        {!isLoadingAnalysis && growthAnalysis && (
          <CompanyGrowthAiAnalysis ticker={ticker} growthAnalysis={growthAnalysis}/>
        )
        }
      </Grid>

      <Grid item xs={12} xl={6} sx={{mb: 3}}>
        {growthData.revenueChange && growthData.estimatedRevenueGrowth && growthData.estimatedRevenueGrowth.length > 0 && !isLoading && (
          <CompanyGrowthChart
          revenueChange={growthData.revenueChange}
            ebitdaChange={growthData.ebitdaChange}
            epsChange={growthData.epsChange}
            estimatedRevenueGrowth={growthData.estimatedRevenueGrowth}
            estimatedEbitdaGrowth={growthData.estimatedEbitdaGrowth}
            estimatedEpsGrowth={growthData.estimatedEpsGrowth}
      />
      )}
    </Grid>
    </Grid>
  );
}
