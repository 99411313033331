import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment-timezone";

interface GeneratedOnProps {
  dateToDisplay: string;
  timeAgo?: boolean;
  onlyDate?: boolean;
  textVariant?: "body1" | "body2" | "caption" | "overline" | "subtitle1" | "subtitle2";
  textPrefix?: string;
  sx?: object;
}

const GeneratedOn: React.FC<GeneratedOnProps> = ({
  dateToDisplay,
  timeAgo = false,
  onlyDate = false,
  textVariant = "body2",
  textPrefix = "Generated on",
  sx = {},
}) => {
  // Determine the user's local timezone
  const userTimezone = moment.tz.guess();

  if (!dateToDisplay) {
    return null;
  }

  // Parse the input date in UTC and convert it to the user's local timezone
  const dateMoment = moment.utc(dateToDisplay).tz(userTimezone);

  // Format the date for display
  const formattedDate = onlyDate ? dateMoment.format("D MMM YYYY") : dateMoment.format("D MMM YYYY, h:mm:ss A");

  // Calculate the relative "time ago" string
  const timeAgoText = dateMoment.fromNow();

  return (
    <Typography
      variant={textVariant}
      sx={{ mt: 1, color: "text.secondary", ...sx }}
    >
      {textPrefix}{" "}
      {timeAgo ? (
        <Tooltip title={formattedDate} arrow>
          <strong>{timeAgoText}</strong>
        </Tooltip>
      ) : (
        <strong>{formattedDate}</strong>
      )}
    </Typography>
  );
};

export default GeneratedOn;
