import {ApexOptions} from 'apexcharts';
// @mui
import CardHeader from '@mui/material/CardHeader';
import {Card, CardProps} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, {useContext, useState} from "react";
// components
import ScoreRadarStyled from "./score-radar-styled";
import {BecomePremiumToAccessDialog} from "../utility_components/become-premium-to-access-dialog";
import {AuthContext} from "../../auth/context/jwt";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  score: number;
  subheader?: string;
  chart: {
    categories: (string | string[])[];
    colors?: string[];
    series: {
      name: string;
      data: number[];
    }[];
    options?: ApexOptions;
  };
}

export default function ScoreRadar({score, subheader, chart, ...other}: Props) {
  const {authenticated} = useContext(AuthContext);
  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);
  return (
    <Card {...other} sx={{height: '100%', width: '100%'}}>
      <CardHeader
        title={
          <Typography variant="body2" align="center">
            <Stack direction="column" alignItems="center" justifyContent="center">
              <Typography variant="h6" align="center">Company Radar</Typography>

            </Stack>
          </Typography>
        }
        subheader={subheader} sx={{mb: 1}}/>

      <Box sx={!authenticated ? {filter: "blur(5px)", cursor: "pointer"} : {}}
           onClick={!authenticated ? () => setPremiumDialogOpen(true) : () => {
           }}>
        <ScoreRadarStyled chart={chart}/>
      </Box>
      <BecomePremiumToAccessDialog open={premiumDialogOpen} onClose={() => {
        setPremiumDialogOpen(false);
        console.log("closed")
      }}
                                   ticker={undefined}/>
    </Card>
  );
}
