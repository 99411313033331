import React, {useEffect, useState} from "react";
import {Grid, LinearProgress} from "@mui/material";

import axios, {endpoints} from "../../utils/axios";
import CompanyBusinessAiAnalysis from "./company-business-ai-analysis";
import CompanyBusinessRevenueBreakdown, {
  RevenueBreakdownType
} from "./company-business-revenue-breakdown";
import {AIAnalysisTypesEnum} from "./types";
import AiProgressbar from "../utility_components/ai-progressbar";

interface CompanyDocumentsProps {
  ticker: string | undefined;
  setAiAnalysesReady?: (key: string) => void | undefined;
}

const defaultRevenueBreakdown: RevenueBreakdownType = {
  product_breakdown: {
    date: '',
    breakdown: {},
  },
  geo_breakdown: {
    date: '',
    breakdown: {},
  }
}

export default function CompanyBusiness({ticker, setAiAnalysesReady}: CompanyDocumentsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);

  const [revenueBreakdown, setRevenueBreakdown] = useState<RevenueBreakdownType>(defaultRevenueBreakdown);
  const [businessAnalysis, setBusinessAnalysis] = useState<any>({});


  useEffect(() => {
    const getRevenueBreakdown = async () => {
      setIsLoading(true);
      try {
        const [revenueBreakdownResponse] = await Promise.all([
          axios.get<any>(`${endpoints.companies.revenueBreakdown}/${ticker}`)
        ]);
        setRevenueBreakdown(revenueBreakdownResponse.data);
      } catch (error) {
        console.error("Error fetching AI analysis:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const getCompanyAiAnalysis = async () => {
      try {
        setIsLoadingAnalysis(true);
        const analysisType = AIAnalysisTypesEnum.BusinessModel;
        const businessAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=${analysisType}`);
        setBusinessAnalysis(JSON.parse(businessAnalysisResponse.data.answer));
        console.log("businessAnalysisResponse", JSON.parse(businessAnalysisResponse.data.answer));
      } catch (error) {
        console.error("Error fetching company valuation AI analysis:", error);
      } finally {
        if (setAiAnalysesReady) {
          setAiAnalysesReady('business');
        }
      }
      setIsLoadingAnalysis(false);
    }

    getRevenueBreakdown();
    getCompanyAiAnalysis()
  }, [ticker, setAiAnalysesReady]);

  if (isLoading) {
    return <LinearProgress/>;
  }
  if (isLoadingAnalysis) {
    return <AiProgressbar/>;
  }
  return (
    <>
      {isLoadingAnalysis && <AiProgressbar/>}
      {!isLoadingAnalysis && (
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{mb: 1}}>
            <CompanyBusinessAiAnalysis aiAnalysis={businessAnalysis}/>
          </Grid>
          <Grid item xs={12} sx={{mb: 1}}>
            <CompanyBusinessRevenueBreakdown product_breakdown={revenueBreakdown.product_breakdown}
                                             geo_breakdown={revenueBreakdown.geo_breakdown}/>
          </Grid>
        </Grid>
      )
      }
    </>
  );
}
