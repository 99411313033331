// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard_old',
  CHAT: '/chat',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
      forgot_password: `${ROOTS.AUTH}/jwt/forgot-password`,
      reset_password: `${ROOTS.AUTH}/jwt/reset-password`,
    },
  },
  main: {
    root: `/`,
    sectors: `/sectors`,
    dashboard: `/dashboard`,
    dashboard_top_companies: `/dashboard/#top-companies`,
    screener: `/screener`,
    custom_analysis: {
      root: `/document-analysis/custom-analysis`,
    },
    document_analysis: {
      root: `/document-analysis`,
      annual_report: `/document-analysis/annual-report`,
      earnings_call: `/document-analysis/earnings-call`,
      custom_analysis: `/document-analysis/custom-analysis`,
    },
    plans: `/plans`,
    home: '#',
    why: '#why',
    features: '#features',
    demo: '#demo',
    tryIt: '#try-it-now'
  },
  informational: {
    scoring_algorithm: '/how-scoring-works',
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/two`,
    three: `${ROOTS.DASHBOARD}/three`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },
  chat: {
    index: `${ROOTS.CHAT}`,
  }
};
